<template>
    <div class="speed">
        <h5header :name="$t('reward.desc38')" :right="false" />
        <div class="speedcon">
            <div class="speed-title">
                <div>{{ $t('market.desc204') }}</div>
                <!-- <p>{{ $t('market.desc205') }}</p> -->
            </div>
            <div class="speed-name flex">
                <img src="../../public/images/new/inviteicon6.png" alt="">
                <div class="flex">{{ $t('market.desc188') }}</div>
                <img src="../../public/images/new/inviteicon5.png" alt="">
            </div>
            <div class="speed-wrappar">
                <div class="speed-desc">
                    {{ $t('market.desc189') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc190') }} {{ $t('market.desc221',{n1:common.formatDate(speedInfo.startTime)}) }} 
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc191') }}
                </div>
                <div class="rate-table">
                    <div class="table-thead flex">
                        <div class="flexcenter">{{ $t('market.desc180') }}</div>
                        <div class="flexcenter">{{ $t('market.desc181') }}</div>
                        <div class="flexcenter">{{ $t('market.desc182') }}</div>
                        <div class="flexcenter">{{ $t('market.desc183') }}</div>
                    </div>
                    <div class="table-tbody">
                        <div class="tbody-info flex" v-for="item in rateList" :key="item.id">
                            <div>{{ item.planetLandSellRatio * 100 }} %</div>
                            <div>{{ item.landReleaseRatio * 100 }} %</div>
                            <div>{{ $t('pool.desc13', { n1: item.releaseDay }) }} </div>
                            <div>{{ item.planetReleaseRatio * 100 }} %</div>
                        </div>
                    </div>
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc192') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc193') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc194') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc195') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc196') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc197') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc198') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc199') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc200') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc201') }}
                </div>
                <div class="speed-desc">
                    {{ $t('market.desc202') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            configInfo4: {},
            speedInfo: {},
            rateList: []
        }
    },
    mounted() {
        // 比例说明
        this.$post(this.URL.planet.config, {}).then(res => {
            if (res.code == 0) {
                this.rateList = res.data;
            }
        })
        this.$post(this.URL.assets.rechargeConfig, {
            activityType: 3
        }).then(res => {
            if (res.code == 0 && res.data != null) {
                console.log(res.data, 1111)
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
                res.data.datetime = this.common.formatDate(res.data.endTime)
                if (todayTime > res.data.endTime) {
                    res.data.act = 2;
                } else if (todayTime < res.data.startTime) {
                    res.data.act = 0;
                    res.data.time = res.data.startTime - todayTime;
                } else {
                    res.data.time = res.data.endTime - todayTime
                    res.data.act = 1;
                }
                res.data.startTime = res.data.startTime - time1;
                res.data.endTime = res.data.endTime - time1;
                if (res.data.awardAccountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.awardAccountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo4 = res.data;
                this.speedInfo = res.data;
            }
        })
    }
}
</script>

<style lang="less" scoped>
.speed {
    padding: 96px 0 180px;
    background: url('../../public/images/reward/speedbg.png') top no-repeat;
    background-size: 100% auto;

    .speedcon {
        width: 1240px;
        margin: 0 auto;

        .speed-title {
            padding: 124px 0;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 24px;

            div {
                margin-bottom: 32px;
                font-size: 56px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: normal;
            }
        }

        .speed-name {
            margin-bottom: 48px;
            justify-content: center;

            img {
                flex: 0 0 95px;
                width: 95px;
                height: 17px;
                margin-top: 12px;
            }

            div {
                padding: 0 8px;
                font-size: 20px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: 40px;

                img {
                    flex: 0 0 40px;
                    width: 40px;
                    height: 40px;
                    margin: 0 8px 0 0;
                }
            }
        }


        .speed-desc {
            margin-bottom: 24px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.80);
        }

        .rate-table {
            width: 752px;
            margin-bottom: 24px;
            border: 1px solid rgba(255, 255, 255, 0.08);
            box-sizing: border-box;

            .table-thead {
                background: rgba(201, 250, 91, 0.10);

                div {
                    flex: 0 0 25%;
                    height: 39px;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.85);
                    text-align: center;
                    border-right: 1px solid rgba(255, 255, 255, 0.08);

                    &:last-child {
                        border-right: 0;
                    }
                }
            }

            .tbody-info {
                border-top: 1px solid rgba(255, 255, 255, 0.08);

                div {
                    flex: 0 0 25%;
                    height: 39px;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.85);
                    text-align: center;
                    border-right: 1px solid rgba(255, 255, 255, 0.08);
                    line-height: 39px;

                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .speed {
        padding: 68px 16px 70px;

        .speedcon {
            width: 100%;

            .speed-title {
                padding: 100px 0 120px;
                font-size: 16px;

                div {
                    font-size: 28px;
                }
            }

            .rate-table {
                width: 100%;
                .table-thead {
                background: rgba(201, 250, 91, 0.10);

                div {
                    flex: 0 0 25%;
                    height: inherit;
                    padding: 12px 6px;
                    font-size: 12px;
                }
            }
            }
        }
    }
}

@media (max-width:767px) {
    .speed {
        padding: 44px 16px;
        background: url('../../public/images/reward/speedbg1.png') top no-repeat;
        background-size: 100% auto;
        .speedcon {
        .speed-title {
            padding: 48px 0;
            font-size: 12px;
            line-height: 14px;

            div {
                margin-bottom: 16px;
                font-size: 20px;
            }
        }
        .speed-name{
            img{
                margin-top: 1px;
            }
            div{
                font-size: 20px;
                line-height: 20px;
                img{
                    flex: 0 0 20px;
                    width: 20px;
                    height: 20px;
                    margin: 0 4px 0 0;
                }
            }
        }
        .speed-desc {
            margin-bottom: 16px;
            font-size: 14px;
        }
    }
    }
}</style>